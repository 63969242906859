import {getBigCoinList, getCoinVersionList, getCoinVersionListByPriceQuery, getSmallCoinList} from "@/axios/apis"

export default {
    data() {
        return {
            // coinKindId 大币种ID
            // coinKindSid 大币种sid
            // coinKindItemId   小币种ID
            // coinKindItemVersionId  版别ID
            coinList: [],
            bigCoinList: [],
            coinItemList: [],
            smallCoinList: [],
            versionList: [],
        }
    },
    methods: {
        /** 大币种改变 */
        async changeCoin() {
            delete this.params.coinKindItemId;
            delete this.params.coinKindItemVersionId;
            if (!this.params.coinKindId) return;
            const coin = this.coinList.find((el) => {
                return el.id === this.params.coinKindId;
            });
            this.$set(this.params, "coinSid", coin.sid);
            this.$set(this.params, "coinKindName", coin.coinKindName);
            await this.coinItemSearch();
        },
        /** 选择小币种 */
        async changeCoinItem() {
            delete this.params.coinKindItemVersionId;
            if (!this.params.coinKindItemId) return;
            await this.getVersionType(this.params.coinKindId, this.params.coinKindItemId)
        },

        /** 搜索，大币种搜索 */
        async coinSearch(str, type) {
            if (!str) return;
            const res = await getBigCoinList(str)
            if (res.status !== "200") return;
            const { records } = res.data;
            this.coinList = records;
            this.bigCoinList = records
            if (type) {
                await this.coinItemSearch(type)
            }
        },

        /** 搜索小币种 +版别 */
        async coinItemSearch(type) {
            const res = await getSmallCoinList(this.params.coinSid || this.params.coinKindSid || this.coinSid)
            if (res.status !== 'SUCCESS') return
            const { coinItem } = res.data;
            this.coinItemList = coinItem;
            this.smallCoinList = coinItem
            if (type) {
                await this.getVersionType(
                    this.params.coinKindId || this.params.coinId,
                    this.params.coinKindItemId || this.params.coinItemId
                )
            }
        },
        /** 获取版别 */
        async getVersionType(coinId, coinItemId) {
            let res
            if (this.versionSearchType === 'queryPrice') {
                res = await getCoinVersionListByPriceQuery(coinId, coinItemId)
            } else {
                res = await getCoinVersionList(coinId, coinItemId)
            }
            if (res.status !== '200') return;
            this.versionList = res.data;
            this.versionCoinList = res.data;
        },
    }
}